.animate {
  &--rotate {
    transform-origin: center;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
