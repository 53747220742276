// base class for all fields of form
.field {
  --border-radius: 8px;
  --text-color: var(--black-100);
  --border-color: var(--black-20);
  --padding: 12px;

  width: 100%;
  outline: none;
  cursor: pointer;
  border-radius: var(--border-radius);
  padding: var(--padding);
  color: var(--text-color);
  font-weight: var(--regular);
  font-size: var(--size-12px);
  line-height: var(--size-14px);
  border: 1px solid var(--border-color);

  &::placeholder,
  &.placeholder {
    color: var(--black-60);
  }

  &:not(&:disabled, .disabled):hover {
    --border-color: var(--black-80);
  }

  &:not(&:disabled):active,
  &:not(&:disabled):focus {
    --border-color: var(--black-60);
  }

  &:disabled,
  &.disabled {
    --border-color: transparent;
    --text-color: var(--black-100);

    cursor: default;
    background-color: var(--grey-40);

    &::placeholder {
      color: var(--text-color);
    }
  }

  &:enabled(.success, .error) {
    padding-left: 0;
  }

  // STATES
  &.success,
  &.success:not(&:disabled):focus {
    --border-color: var(--green-100);
  }

  &.error,
  &.error:not(&:disabled):focus {
    --border-color: var(--red-90);
  }

  // SIZES
  &.small {
    --border-radius: 4px;
    --padding: 8px 12px;
  }

  &__row {
    position: relative;
  }

  &__container {
    margin-bottom: 24px;
  }

  &__error-message {
    padding: 4px 6px;
    color: var(--red-100);
    font-weight: var(--regular);
    font-size: var(--size-10px);
    line-height: var(--size-14px);

    &.up {
      margin-top: -22px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
