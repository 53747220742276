// font name, path, font weight, font style (not necessarily)

$fonts: (
  (e-Ukraine, "e-Ukraine-Thin", 100),
  (e-Ukraine, "e-Ukraine-UltraLight", 200),
  (e-Ukraine, "e-Ukraine-Light", 300),
  (e-Ukraine, "e-Ukraine-Regular", 400),
  (e-Ukraine, "e-Ukraine-Medium", 600),
  (e-Ukraine, "e-Ukraine-Bold", 700),
  (e-UkraineHead, "e-UkraineHead-Thin", 100),
  (e-UkraineHead, "e-UkraineHead-UltraLight", 200),
  (e-UkraineHead, "e-UkraineHead-Light", 300),
  (e-UkraineHead, "e-UkraineHead-Regular", 400),
  (e-UkraineHead, "e-UkraineHead-LOGO", 500),
  (e-UkraineHead, "e-UkraineHead-Medium", 600),
  (e-UkraineHead, "e-UkraineHead-Bold", 700)
);

@each $name, $url, $weight, $style in $fonts {
  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src: url("../../assets/fonts/#{$url}.ttf") format("truetype");
  }
}
