@import "../abstracts/mixins";

.med-datepicker {
  --nav-border-color: var(--grey-100);

  position: relative;
  color: var(--black-60);

  &.disabled {
    .med-datepicker {
      &__icon {
        &--calendar {
          color: var(--grey-100);
        }
      }
    }
  }

  &__host {
    width: 100%;
  }

  &__field {
    padding-right: 50px;
  }

  &__nav {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
  }

  &__icon {
    cursor: pointer;
    display: flex;
    background-color: transparent;
    padding: 2px;
    border-radius: 4px;

    &--calendar {
      color: var(--black-100);
    }

    &--delete {
      margin-right: 4px;

      &:hover {
        color: var(--black-100);
        background-color: var(--grey-50);
      }

      &.hide {
        display: none;
      }
    }
  }

  &__panel {
    @include box-shadow-opacity(0, 4px, 12px, #000, 0.22);

    background: var(--white);
    border: 1px solid var(--nav-border-color);
    border-radius: 8px;
    z-index: var(--modal-z-index);

    &.flexible {
      display: flex;
    }

    .p-datepicker {
      &-header {
        border-bottom: 1px solid var(--grey-100);
        padding: 6px 8px;
      }

      &-prev,
      &-next {
        --nav-border-color: var(--purple-100);

        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;

        &::before {
          content: "";
          border: solid var(--nav-border-color);
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
        }
      }

      &-prev::before {
        transform: rotate(135deg);
      }

      &-next::before {
        transform: rotate(-45deg);
      }

      &-month,
      &-year,
      &-decade {
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: var(--black-100);
        font-weight: var(--regular);
        font-size: var(--size-14px);

        &:hover {
          color: var(--purple-100);
        }
      }

      &-calendar {
        &-container {
          padding-bottom: 12px;
        }

        thead {
          background-color: var(--grey-40);
        }

        th,
        td .p-element {
          padding: 8px 7px;
          color: var(--black-100);
          font-weight: var(--regular);
          font-size: var(--size-12px);
        }

        th:first-child {
          padding-left: 23px;
        }

        th:last-child {
          padding-right: 23px;
        }

        td:first-child .p-element {
          margin-left: 16px;
        }

        td:last-child .p-element {
          margin-right: 16px;
        }

        td .p-element {
          border-radius: 4px;

          &:hover:not(.p-disabled) {
            color: var(--purple-100);
            background: var(--purple-10);
          }

          &.p-highlight {
            color: var(--white);
            background-color: var(--purple-100);
          }

          &.p-disabled {
            color: var(--grey-100);
          }
        }
      }
    }

    .p-yearpicker,
    .p-monthpicker {
      padding: 26px 12px;

      &-year,
      &-month {
        padding: 8px 0;
        border-radius: 4px;
        color: var(--black-100);
        font-weight: var(--regular);
        font-size: var(--size-12px);

        &:hover {
          background-color: var(--grey-20);
        }

        &.p-highlight {
          color: var(--white);
          background-color: var(--purple-100);
        }
      }
    }

    .p-timepicker {
      padding-right: 12px;

      span {
        color: var(--black-100);
        font-weight: var(--regular);
        font-size: var(--size-16px);
      }

      .p-ripple {
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;

        &::before {
          content: "";
          border: solid var(--nav-border-color);
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
        }

        &:hover {
          --nav-border-color: var(--purple-100);
        }

        &:first-child {
          transform: rotate(-135deg);
        }

        &:last-child {
          transform: rotate(45deg);
        }
      }

      .p-separator {
        margin-left: 2px;
      }
    }
  }
}
