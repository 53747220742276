@import "../abstracts/variables";
@import "../abstracts/mixins";

.title {
  font-weight: var(--medium);
  color: var(--black-100);

  &.double-large {
    font-size: var(--size-56px);
    font-weight: var(--extra-bold);
    line-height: var(--size-64px);

    @include max-media-devices("md") {
      font-size: var(--size-28px);
      line-height: var(--size-34px);
    }
  }

  &.extra-large {
    font-size: var(--size-40px);
    line-height: var(--size-46px);

    @include max-media-devices("md") {
      font-size: var(--size-24px);
      line-height: var(--size-32px);
    }
  }

  &.large {
    font-size: var(--size-32px);
    line-height: var(--size-40px);

    @include max-media-devices("md") {
      font-size: var(--size-22px);
      line-height: var(--size-30px);
    }
  }

  &.medium {
    font-size: var(--size-28px);
    line-height: var(--size-34px);

    @include max-media-devices("md") {
      font-size: var(--size-20px);
      line-height: var(--size-28px);
    }
  }

  &.small {
    font-size: var(--size-24px);
    line-height: var(--size-32px);

    @include max-media-devices("md") {
      font-size: var(--size-18px);
      line-height: var(--size-24px);
    }
  }

  &.extra-small {
    font-size: var(--size-20px);
    line-height: var(--size-28px);

    @include max-media-devices("md") {
      font-size: var(--size-16px);
      line-height: var(--size-22px);
    }
  }
}

.text {
  font-weight: var(--regular);
  color: var(--black-100);

  &.extra-large {
    font-size: var(--size-18px);
    line-height: var(--size-24px);
    font-weight: var(--medium);

    @include max-media-devices("md") {
      font-size: var(--size-14px);
      line-height: var(--size-20px);
    }
  }

  &.large {
    font-size: var(--size-16px);
    line-height: var(--size-22px);

    @include max-media-devices("md") {
      font-size: var(--size-13px);
      line-height: var(--size-18px);
    }
  }

  &.medium {
    font-size: var(--size-14px);
    line-height: var(--size-20px);

    @include max-media-devices("md") {
      font-size: var(--size-11px);
      line-height: var(--size-14px);
    }
  }

  &.small {
    font-size: var(--size-12px);
    line-height: var(--size-16px);

    @include max-media-devices("md") {
      font-size: var(--size-10px);
      line-height: var(--size-14px);
    }
  }

  &.extra-small {
    font-size: var(--size-10px);
    line-height: var(--size-14px);

    @include max-media-devices("md") {
      line-height: var(--size-12px);
    }
  }

  &--wrap {
    word-wrap: break-word;
    hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }
}

.button-label {
  font-weight: var(--medium);
  color: var(--black-100);

  &-medium {
    font-size: var(--size-13px);
    line-height: var(--size-22px);
  }
}
