@import "./variables";

@mixin bg-color-opacity($color, $opacity: 1) {
  background-color: rgba($color, $opacity);
}

@mixin box-shadow-opacity($x, $y, $blur, $color, $opacity) {
  box-shadow: $x $y $blur rgba($color, $opacity);
}

@mixin min-media-devices($key) {
  $breakpoint: #{map.get($breakpoints, $key)};
  @media (min-width: $breakpoint) {
    @content;
  }
}

// why 0.02px https://getbootstrap.com/docs/5.0/layout/breakpoints/
@mixin max-media-devices($key) {
  $breakpoint: calc(#{map.get($breakpoints, $key)} - 0.02px);
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin min-and-max-media-devices($min-key, $max-key) {
  $min-breakpoint: #{map.get($breakpoints, $min-key)};
  $max-breakpoint: #{map.get($breakpoints, $max-key)};
  @media (min-width: $min-breakpoint) and (max-width: $max-breakpoint) {
    @content;
  }
}

@mixin med-scroll {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    --background-scroll: var(--grey-40);

    background: var(--background-scroll);
    border-radius: 6px;

    &:hover {
      --background-scroll: var(--grey-60);
    }
  }
}
