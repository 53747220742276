@import "../../abstracts/variables";
@import "../../abstracts/mixins";

$columns: 12;

.col {
  flex: 1 0 0;
}

@mixin col-list($columns, $breakpoint, $tag) {
  @for $i from 1 through $columns {
    @if $breakpoint > 0 {
      @include min-media-devices($tag) {
        .#{$tag}\:col-#{$i} {
          flex: 0 0 auto;
          width: calc((100% * $i) / $columns);
        }
      }
    } @else {
      .col-#{$i} {
        flex: 0 0 auto;
        width: calc((100% * $i) / $columns);
      }
    }
  }
}

@each $key, $breakpoint in $breakpoints {
  @include col-list($columns, $breakpoint, $key);
}
