.med-tooltip {
  &__tooltip-wrap {
    z-index: 9;
    position: fixed;
    transform: translate(-99999px, -9999px);

    &[data-popper-reference-hidden] {
      visibility: hidden;
      pointer-events: none;
    }

    &[data-popper-placement^="top"] {
      padding-bottom: 10px;

      & .med-tooltip {
        &__arrow {
          bottom: -4px;
        }
      }
    }

    &[data-popper-placement^="bottom"] {
      padding-top: 10px;

      & .med-tooltip {
        &__arrow {
          top: -4px;
        }
      }
    }

    &[data-popper-placement^="left"] {
      padding-right: 10px;

      & .med-tooltip {
        &__arrow {
          right: -4px;
        }
      }
    }

    &[data-popper-placement^="right"] {
      padding-left: 10px;

      & .med-tooltip {
        &__arrow {
          left: -4px;
        }
      }
    }
  }

  &__tooltip {
    color: var(--black-100);
    background-color: var(--white);
    padding: 12px 8px;
    border-radius: 8px;
    font-weight: var(--regular);
    font-size: var(--size-12px);
    line-height: var(--size-16px);
    filter: drop-shadow(0 4px 12px rgb(0 0 0 / 22%));
  }

  &__arrow {
    visibility: hidden;

    &,
    &::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
    }

    &::before {
      content: "";
      visibility: visible;
      transform: rotate(45deg);
    }
  }
}
