:root {
  // Colors
  // blue
  --blue-105: #23344a;
  --blue-100: #0037a3;
  --blue-90: #1a4bac;
  --blue-80: #335fb5;
  --blue-70: #4c73bf;
  --blue-65: #71a1ff;
  --blue-60: #6687c8;
  --blue-50: #809bd1;
  --blue-40: #99afda;
  --blue-30: #b3c3e3;
  --blue-20: #ccd7ed;
  --blue-10: #e5ebf6;
  --blue-5: #f5f9ff;

  // purple
  --purple-100: #1f1f49;
  --purple-90: #353573;
  --purple-80: #404087;
  --purple-70: #5151a8;
  --purple-60: #6060c4;
  --purple-50: #6e6ede;
  --purple-40: #8d8de6;
  --purple-30: #acaced;
  --purple-20: #cbcbf4;
  --purple-15: #ebedff;
  --purple-10: #f3f3fd;

  // black
  --black-100: #000;
  --black-90: #1a1a1a;
  --black-80: #333;
  --black-70: #4c4c4c;
  --black-60: #666;
  --black-50: #808080;
  --black-40: #999;
  --black-30: #b3b3b3;
  --black-20: #ccc;
  --black-10: #e5e5e5;

  // grey
  --grey-100: #c7c7c7;
  --grey-80: #d0d0d0;
  --grey-60: #ddd;
  --grey-50: #ededed;
  --grey-45: #d9d9d9;
  --grey-40: #e9e9e9;
  --grey-20: #f4f4f4;
  --grey-10: #f9f9f9;
  --white: #fff;

  // yellow
  --yellow-100: #ffbd12;
  --yellow-90: #ffc32a;
  --yellow-80: #ffcf55;
  --yellow-70: #ffd56a;
  --yellow-60: #ffdb7f;
  --yellow-50: #ffe195;
  --yellow-40: #ffe7aa;
  --yellow-30: #ffedbf;
  --yellow-20: #fff3d4;
  --yellow-10: #fff9ea;

  // green
  --green-100: #00c95c;
  --green-90: #1ace6d;
  --green-80: #33d47d;
  --green-70: #4dd98d;
  --green-60: #66df9e;
  --green-50: #80e4ae;
  --green-40: #99e9be;
  --green-30: #b3efce;
  --green-20: #ccf4de;
  --green-10: #e6faef;

  // red
  --red-100: #fb3333;
  --red-90: #fb4848;
  --red-80: #fc5c5c;
  --red-70: #fc7070;
  --red-60: #fd8585;
  --red-50: #fd9999;
  --red-40: #fdadad;
  --red-30: #fec2c2;
  --red-20: #fed6d6;
  --red-10: #ffebeb;

  // Gradients
  // purple
  --purple-radial-gradient: radial-gradient(65.16% 115.07% at 20.65% 21.85%, #6e6edf 0%, #28285d 69.52%, #1f1f49 100%);
  --revert-purple-gradient: radial-gradient(205.16% 115.07% at 20.65% 21.85%, #6e6edf 0%, #28285d 69.52%, #1f1f49 100%);

  // blue
  --base-blue-gradient: linear-gradient(357.31deg, #0037a3 -5.35%, #71a1ff 102.33%);
  --left-blue-gradient: linear-gradient(97.14deg, #0037a3 -5.35%, #71a1ff 102.33%);
  --right-blue-gradient: radial-gradient(51.67% 124.53% at 34.24% -5.59%, #71a1ff 0%, #0037a3 100%);
  --top-blue-gradient: linear-gradient(180deg, #0037a3 0%, #b3ccff 100%);
  --ripple-btn-gradient: #0037a3 radial-gradient(circle, transparent 1%, #0037a3 1%) center/15000%;

  // grey
  --base-grey-gradient: linear-gradient(357.31deg, #000 -5.35%, #777 102.33%);
  --left-grey-gradient: linear-gradient(97.14deg, #000 6.92%, #737373 97.65%);
  --right-grey-gradient: radial-gradient(51.67% 124.53% at 34.24% -5.59%, #737373 0%, #000 100%);
  --multicolor-gradient: radial-gradient(51.67% 124.53% at 34.24% -5.59%, #737373 0%, #000 100%);

  // green
  --left-green-gradient: linear-gradient(100deg, #006d32 0%, #3ac67a 100%);
  --right-green-gradient: linear-gradient(270deg, #006d32 0%, #3ac67a 100%);
  --base-green-gradient: linear-gradient(270deg, #006d32 0%, #3ac67a 100%);
  --bottom-green-gradient: linear-gradient(180deg, #216842 0%, #50d38c 100%);

  // other
  --banner-yellow-gradient: radial-gradient(ellipse at 50% 110%, #2c96ff, transparent 75%);
  --banner-blue-gradient: radial-gradient(ellipse at 50% -10%, var(--yellow-100), transparent 75%);
  --yellow-blue-gradient: var(--banner-yellow-gradient), var(--banner-blue-gradient);

  // Font-families
  --ukraine: "e-Ukraine";
  --e-ukrainehead: "e-UkraineHead";

  // Font-sizes
  --size-10px: 10px;
  --size-11px: 11px;
  --size-12px: 12px;
  --size-13px: 13px;
  --size-14px: 14px;
  --size-16px: 16px;
  --size-18px: 18px;
  --size-20px: 20px;
  --size-22px: 22px;
  --size-24px: 24px;
  --size-28px: 28px;
  --size-30px: 30px;
  --size-32px: 32px;
  --size-34px: 34px;
  --size-40px: 40px;
  --size-46px: 46px;
  --size-56px: 56px;
  --size-64px: 64px;

  // Font-weights
  --thin: 100;
  --ultra-light: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;

  // Animations
  --animation-duration: 0.2s;

  // Base
  --header-margin-bottom: 16px;

  // Scroll
  --horizontal-scroll-height: 16px;

  // Overlay
  --modal-z-index: 1001;
  --controls-z-index: 1002;
}
