.flex {
  display: flex;

  &-center {
    align-items: center;
  }

  &-between {
    justify-content: space-between;
  }

  &-end {
    justify-content: flex-end;
  }

  &-justify-center {
    justify-content: center;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}
