$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0,
  ),
  1: (
    x: ($spacer-x * 0.25),
    y: ($spacer-y * 0.25),
  ),
  2: (
    x: ($spacer-x * 0.5),
    y: ($spacer-y * 0.5),
  ),
  3: (
    x: ($spacer-x * 0.75),
    y: ($spacer-y * 0.75),
  ),
  4: (
    x: $spacer-x,
    y: $spacer-y,
  ),
  5: (
    x: ($spacer-x * 1.25),
    y: ($spacer-y * 1.25),
  ),
  6: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5),
  ),
  7: (
    x: ($spacer-x * 1.75),
    y: ($spacer-y * 1.75),
  ),
  8: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2),
  ),
  9: (
    x: ($spacer-x * 2.25),
    y: ($spacer-y * 2.25),
  ),
  10: (
    x: ($spacer-x * 2.5),
    y: ($spacer-y * 2.5),
  ),
  11: (
    x: ($spacer-x * 2.75),
    y: ($spacer-y * 2.75),
  ),
  12: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3),
  ),
);

@mixin spacing() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x: #{map.get($lengths, x)};
      $length-y: #{map.get($lengths, y)};

      .#{$abbrev}-#{$size} { #{$prop}: $length-y $length-x !important; }
      .#{$abbrev}t-#{$size} { #{$prop}-top: $length-y !important; }
      .#{$abbrev}r-#{$size} { #{$prop}-right: $length-x !important; }
      .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
      .#{$abbrev}l-#{$size} { #{$prop}-left: $length-x !important; }
    }
  }
}

@include spacing;
