$text-weights: (
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700
);

@mixin fill-text-weights() {
  @each $weight-name, $weight in $text-weights {
    .text--#{$weight-name} {
      font-weight: $weight;
    }
  }
}

@include fill-text-weights;
