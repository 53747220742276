@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;

  display: flex;
  width: 100%;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  &.align-items-start {
    align-items: flex-start;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}
