$default-color-scheme-config: (
  "from": 10,
  "to": 100,
  "step": 10
);
$color-scheme-list: (
  blue: $default-color-scheme-config,
  purple: $default-color-scheme-config,
  black: $default-color-scheme-config,
  grey: $default-color-scheme-config,
  yellow: $default-color-scheme-config,
  green: $default-color-scheme-config,
  red: $default-color-scheme-config,
);

@mixin fill-color-modifiers($modifier-type, $prop) {
  @each $color, $config in $color-scheme-list {
    $i: map.get($config, "from");
    @while $i <= map.get($config, "to") {
      .#{$modifier-type}--#{'' + $color}-#{$i} {
        #{$prop}: var(--#{$color}-#{$i});
      }

      $i: $i + map.get($config, "step");
    }
  }
}

@include fill-color-modifiers(text, color);
@include fill-color-modifiers(bg, background-color);
