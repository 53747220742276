/* You can add global styles to this file, and also import other styles files */
@use "sass:map";

@import "./styles/vendors/normalize";

@import "./styles/abstracts/fonts";
@import "./styles/abstracts/mixins";
@import "./styles/abstracts/global-variables";
@import "./styles/abstracts/variables";

@import "./styles/base/animation";
@import "./styles/base/spaces";
@import "./styles/base/sizes";
@import "./styles/base/typography";
@import "styles/base/color-modifiers";
@import "styles/base/text-weight-modifiers";
@import "./styles/base/grid/container";
@import "./styles/base/grid/row";
@import "./styles/base/grid/cols";
@import "./styles/base/display";
@import "./styles/base/field";
@import "./styles/base/text";

@import "./styles/layouts/med-layout";

@import "./styles/components/popup";
@import "./styles/components/tooltip";
@import "./styles/components/datepicker";

body {
  font-family: var(--ukraine), Helvetica, Arial, sans-serif;
  font-size: var(--size-14px);
  line-height: var(--size-17px);
}

* {
  @include med-scroll;
}
