@import "../../abstracts/mixins";

.container {
  --pading: 16px;
  --max-width: 100%;

  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  padding-left: var(--pading);
  padding-right: var(--pading);

  @include max-media-devices("md") {
    --pading: 8px;
  }

  @include min-media-devices("md") {
    --max-width: 720px;
  }

  @include min-media-devices("lg") {
    --max-width: 960px;
  }

  @include min-media-devices("xl") {
    --max-width: 1140px;
  }

  @include min-media-devices("xxl") {
    --max-width: calc(100% - 4%);
  }

  &.fluid {
    --max-width: 100%;
  }
}
