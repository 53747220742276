.med-layout {
  display: table;
  max-width: 100%;

  &--sticky {
    position: sticky;
  }

  &__main {
    display: flex;
  }

  &__banner {
    position: sticky;
    left: 0;
    display: block;
  }

  &__header {
    position: sticky;
    align-self: self-start;
    display: block;
    z-index: 3;
    top: 0;
    left: 0;
    margin-bottom: var(--header-margin-bottom);
  }

  &__right,
  &__left {
    z-index: 3;
    flex: 0 0 250px;
    position: sticky;
    background-color: var(--white);
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  &__bottom {
    z-index: 3;
    position: sticky;
    top: 8px;
    padding-bottom: 8px;
    background-color: var(--white);
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--white);
  }

  &__footer-content {
    height: 100%;
    color: var(--white);
    padding: 8px;
    border-radius: 8px 8px 0 0;
    backdrop-filter: blur(10px);
    background: var(--black-90);
  }
}
