.text {
  &.multiline-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &.clamp-1 {
      -webkit-line-clamp: 1;
    }

    &.clamp-5 {
      -webkit-line-clamp: 5;
    }
  }
}