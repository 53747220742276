.med-popup {
  &__tooltip {
    z-index: 9;
    position: fixed;
    transform: translate(-99999px, -9999px);
    font-weight: var(--medium);
    font-size: var(--size-14px);
    line-height: var(--size-18px);
    color: var(--black-100);
    padding: 12px 8px;
    background-color: var(--white);
    border: 1px solid var(--black-20);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 22%);

    &[data-popper-reference-hidden] {
      visibility: hidden;
      pointer-events: none;
    }
  }
}
